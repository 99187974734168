import { useEffect, useState } from "react";
import styled from "styled-components";
// import { popularProducts } from "../data"
import Product from "./Product";
import axios from "axios";
//
const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #faf5e4;
`;

const Products = ({ cat, filters, sort }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    //
    const getProducts = async () => {
      try {
        let url = `${process.env.REACT_APP_BASE_URL}/products`;
        if (cat) {
          url += `?category=${cat}`;
        }
        console.log(`${process.env.REACT_APP_BASE_URL}`);
        const res = await axios.get(url);
        setProducts(res.data);
      } catch (err) {}
    };
    getProducts();
    //
  }, [cat]);

  //
  useEffect(() => {
    cat &&
      setFilteredProducts(
        products.filter((item) =>
          Object.entries(filters).every(([key, value]) =>
            item[key].includes(value)
          )
        )
      );
  }, [products, cat, filters]);

  //
  useEffect(() => {
    if (sort === "newest") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.createdAt - b.createdAt)
      );
    } else if (sort === "asc") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.price - b.price)
      );
    } else {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => b.price - a.price)
      );
    }
  }, [sort]);

  //
  return (
    <Container>
      {cat
        ? filteredProducts.map((item) => <Product item={item} key={item._id} />)
        : products
            .slice(0, 8)
            .map((item) => <Product item={item} key={item._id} />)}
    </Container>
  );
};

export default Products;
